// config.js
const config = {
    // apiUrl: 'http://localhost:3001',
    apiUrl: 'https://cargotekeli.kz:3001',
    
    nameCargo: 'Cargo Tekeli'
  };
  
  
export default config;
  